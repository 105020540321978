import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import HubspotForm from "react-hubspot-form"
import Heading from "@components/elements/heading"
import Div from "@components/elements/div"
import { Form } from "@components/common/form"

const ContactUs = () => {
  return (
    <Layout
      title="Contact Us"
      description="Let's get in touch and find out how we can help with your dashboard questions."
      fullWidth
      marginless
      hideOverflow
      microsite
    >
      <Container fullWidth noPadding>
        <Div margin="8rem auto 4rem" textContainer>
          <Heading margin="0 0 1rem">Get in touch with us</Heading>
          <Form>
            <HubspotForm
              portalId={"7477725"}
              formId={"41ddd3bb-1427-4742-83f9-000628bfe255"}
            />
          </Form>
        </Div>
      </Container>
    </Layout>
  )
}

export default ContactUs
